import React, {useState} from "react"
import {  CardElement} from '@stripe/react-stripe-js';
import styled from "styled-components"
import { Colours } from '../../Layout/colours';
import CheckoutError from './CheckoutError';

const FormContainer = styled.div`        
  padding-top:15px;
  padding-right:15px;
  border-radius: 8px;	        
`;

const FormInput = styled.input`
  background: ${Colours.Overcast};
  border: none;
  border-radius: 4px;
  font-size: 15px;
  margin: 0;
  outline: 0;
  padding: 10px;
  width: 100%;
  box-sizing: border-box; 
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;     
  -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  margin-bottom: 30px;

  :focus{
  background: ${Colours.Focus};
  }
`;

const FormTextArea = styled.textarea`
  background: ${Colours.Overcast};
  border: none;
  border-radius: 4px;
  font-size: 15px;
  margin: 0;
  outline: 0;
  padding: 10px;
  width: 100%;
  min-height:120px;
  box-sizing: border-box; 
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;         
  -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
  margin-bottom: 30px;

  :focus{
      background: ${Colours.Focus};
  }
`;

const FormButton = styled.button`
  position: relative;
  display: block;
  padding: 19px 39px 18px 39px;
  color: #FFF;
  margin: 0 auto;
  background: ${Colours.Darkest};
  font-size: 18px;
  text-align: center;
  font-style: normal;
  width: 100%;
  border: 1px solid ${Colours.Focus};;
  border-width: 1px 1px 3px;
  margin-bottom: 10px;

  :hover:enabled{
      background: ${Colours.Dark};
  }

  :disabled {
  opacity: 0.2;
  }
`;

const iframeStyles = {
  base: {
    color: "#515151",
    fontSize: "16px",
    iconColor: "#fff",
    "::placeholder": {
      color: "#515151"
    }
  },
  invalid: {
    iconColor: "#ff0033",
    color: "#ff0033"
  },
  complete: {
    iconColor: "#cbf4c9"
  }
};

const cardElementOpts = {
  iconStyle: "solid",
  style: iframeStyles,
  hidePostalCode: true
};

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  
  & .StripeElement {
    width: 100%;
    padding: 15px;
    background: ${Colours.Overcast};
    color: black;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    margin: 0;
    outline: 0;
    padding: 10px;
    width: 100%;
    box-sizing: border-box; 
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;     
    -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
    box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
    margin-bottom: 30px;   
  }
  
  & .StripeElement--focus{
    background: ${Colours.Focus};
  }
`;

const ClassicPaymentForm = () => {
  const [state, setState] = React.useState({});
  const [canSend, setCanSend] = React.useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const handleChange = (e) => {
    var latestState = { ...state, [e.target.name]: e.target.value };
    setState(latestState);

    const validName = latestState['name'] !== undefined && latestState['name'] !== '';
    const validEmail = latestState['email'] !== undefined && latestState['email'] !== '';
    const validAddress = latestState['address'] !== undefined && latestState['address'] !== '';
    const validCity = latestState['city'] !== undefined && latestState['city'] !== '';
    const validPostcode = latestState['postcode'] !== undefined && latestState['postcode'] !== '';
    setCanSend(validName && validEmail && validAddress && validCity && validPostcode);
  }

  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  return (    
      <FormContainer>                    
        <FormInput type="text" name="name" placeholder="Your Name *" required={true} onChange={handleChange} />
        <FormInput type="email" name="email" placeholder="Your Email *" required={true} onChange={handleChange} />
        <FormInput type="text" name="phone" placeholder="Your phone" required={false} onChange={handleChange} />
        <FormInput type="text" name="address" placeholder="Billing Address *" required={true} onChange={handleChange} />
        <FormInput type="text" name="city" placeholder="Billing city *" required={true} onChange={handleChange} />
        <FormInput type="text" name="postcode" placeholder="Billing postcode *" required={true} onChange={handleChange} />        
        <FormTextArea name="message" placeholder="Other details" onChange={handleChange}></FormTextArea>        
        <CardElementContainer>
          <CardElement options={cardElementOpts} onChange={handleCardDetailsChange}/>
        </CardElementContainer> 
        {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
        <FormButton disabled={!canSend} type="submit">Pay</FormButton>
      </FormContainer>    
  );
};

export default ClassicPaymentForm;
