import React from "react"
import ClassicPaymentForm from './prebuilt/ClassicPaymentForm'


const CheckoutCart = () => {
  return (       
    <ClassicPaymentForm />    
  );
};

export default CheckoutCart;