import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout/layout"
import { Sizes } from '../components/Layout/sizes';
import CheckoutCart from '../components/Shop/checkoutCart';

export const Container = styled.div`
    display: flex;    
    flex-direction: column;
    justify-content: space-between;      
    width: 75%;

    @media screen and ${Sizes.mobileS} {
      flex-direction: row;            
    }
`;

const CheckoutPage = () => {
  return (
    <Layout>
        <Container>          
            <CheckoutCart />          
        </Container>
    </Layout>    
  );
};

export default CheckoutPage;